import React, {useState, useEffect} from 'react'

const Alerta = (props) => {
    
    const [alertaActiva, setAlertaActiva] = useState(0)
    const [mensajeAlerta, setMensajeAlerta] = useState(0)


    useEffect( () => {
        if (mensajeAlerta) {
            console.log(mensajeAlerta)
            setAlertaActiva(true)
            setTimeout(function(){
                setAlertaActiva(false)
            }, 40000)
        }
    }, [mensajeAlerta])


    return (
        <div id="alertBack" className={ alertaActiva ? 'alertaActiva':''}>
            <div className="alertaNotification">Mensaje de Prueba</div>
        </div>
    )
}

export default Alerta