import React, {useState} from 'react'
import useInterval from '../../Hooks'

const Estado2 = (props) => {
    
    const [estado2, setEstado] = useState(props.es)
    let estadoHelper = false;

    useInterval( () => {
        estadoHelper = !estadoHelper
        estadoHelper ? setEstado(props.es):setEstado(props.en)
    }, 3000)

    return (
        <div className={`indicadorEstado2 ${props.estilo}`}>
            <span className="estado2">{estado2}</span>
        </div>
        
    )
}

export default Estado2