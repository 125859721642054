import React, { useEffect, useState, useContext } from 'react'
import Countdown from 'react-countdown-now'
import moment from 'moment'
import './Notificacion.css'
import ItinerarioContext from '../../context/ItinerarioContext';
import arrow from '../../assets/images/arrow.png';
import tabsaqr from '../../assets/images/qrtabsa.png';



const Notificacion = (props) => {
    
    const [nextTrip, setNextTrip] = useState(0)
    const itinerarioCtx = useContext(ItinerarioContext)

    useEffect( () => {
        const getData = async () => {
            const data = await itinerarioCtx.nextTrip
            if ( data ) {
                if (data.info.resultados > 0) {
                    const trip = await data.data
                    const newTrip = trip.filter( (trip) => {
                        return ! trip.origen.indexOf("Punta Arenas")
                    })
                    setNextTrip(newTrip)
                } else {
                    setNextTrip(false)
                }
            }
        }

        getData()

    }, [itinerarioCtx.nextTrip])

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            console.log('completed')
            setNextTrip(0)
            return ""
        } else {
            hours = hours < 10 ? `0${hours}`:hours
            minutes = minutes < 10 ? `0${minutes}`:minutes
            seconds = seconds < 10 ? `0${seconds}`:seconds
            return <span> {hours}:{minutes}:{seconds}</span>
        }
    }

    const estadoRender = () => {

        if (nextTrip.length > 0) {
            switch(nextTrip[0].estado) {
                case 8:
                    return (
                        <>
                        Próximo zarpe con destino a {nextTrip[0].destino} en 
                        <Countdown
                            key="1"  
                            daysInHours={true} 
                            date={moment(nextTrip[0].zarpeOriginal, 'Y-MM-DD HH:mm:ss').format('Y-MM-DD HH:mm:ss')} 
                            renderer={renderer} />                           
                        </>
                    )
                case 2:
                    return `El cruce hacia ${nextTrip[0].destino} ha sido cancelado.`
                case 0:
                    return `El cruce hacia ${nextTrip[0].destino} ha sido cancelado.`
                case 4:
                    return `El cruce hacia ${nextTrip[0].destino} se encuentra suspendido.`
                default:
                    return `Bienvenido a Transbordadora Austral Broom.`
            }
        } else {
            return "No hay más cruces desde Punta Arenas por hoy. Muchas gracias."
        }
    }

	return (
        <div id="notificacion">
            <div className="notificationContent">
                { ! nextTrip
                    ?   <div className="countdown" style={{"justify-content":"center", "text-align":"center","font-size":"40px", "margin-top":"36px", "color": "black"}}>¡Accede a nuestras RRSS y Descarga la APP de TABSA ahora!<img src={arrow} className="arrow1" style={{"width":"60px"}}></img><img className="countdown2" style={{width:'98px','margin-top':'2px'}} src={tabsaqr}/></div>
                    :   (
                        
                       <div className="countdown" style={{"justify-content":"center", "text-align":"center","font-size":"40px", "margin-top":"36px", "color": "black"}}>¡Accede a nuestras RRSS y Descarga la APP de TABSA ahora!<img src={arrow} className="arrow1" style={{"width":"60px"}}></img><img className="countdown2" style={{width:'98px','margin-top':'2px'}} src={tabsaqr}/></div>
                    // <div className="countdown3">{estadoRender()} </div>
                        )
                }
            </div>
        </div>
	)
}

export default Notificacion;