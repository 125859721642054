import React, {useState} from 'react'
import useInterval from '../../Hooks'

const Estado = (props) => {
    
    const [estado, setEstado] = useState(props.es)
    let estadoHelper = false;

    useInterval( () => {
        estadoHelper = !estadoHelper
        estadoHelper ? setEstado(props.es):setEstado(props.en)
    }, 3000)

    return (
        <div className={`indicadorEstado ${props.estilo}`}>
            <span className="estado">{estado}</span>
        </div>
        
    )
}

export default Estado