import React from 'react'
import ClimaWidget from '../clima/ClimaWidget'
import Reloj from '../reloj/Reloj'
import TarifaWidget from '../tarifa/TarifaWidget'
import './Footer.css'

const Footer = () => {

    return (
        <section className="footer">
            <div id="footer" className="row flex-space-between"><TarifaWidget />
                <div id="masInformacion">
                    {/* <ClimaWidget /><Reloj /> */}
                </div>
            </div>
        </section>
    )
}
export default Footer