import React, { useState, useEffect, Component } from 'react'
import ReactPlayer from 'react-player'
import axios from 'axios'
import './Mediabox.css'
import ReactDOM from 'react-dom'

const Url= 'https://youtube.com/playlist?list=PLgbPTEB4Kn6jjkd3MiD9tX9fWqRrmB5fT';
class Timer extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
		seconds: parseInt(props.startTimeInSeconds, 10) || 0
	  };
	}


  
	tick() {
	  this.setState(state => ({
		seconds: state.seconds + 1
	  }));
	}
  
	componentDidMount() {
	  this.interval = setInterval(() => this.tick(), 1000);
	}
  
	componentWillUnmount() {
	  clearInterval(this.interval);
	}
  
	formatTime(secs) {
	//   let hours   = Math.floor(secs / 3600);
	  let minutes = Math.floor(secs / 60) % 60;
	  let seconds = secs %60;
	  return [minutes, seconds]
		  .map(v => ('' + v).padStart(2, '0'))
		  .filter((v,i) => v !== '00' || i > 0)
		  .join(':');
		}
		render() {
			if (this.formatTime(this.state.seconds) == '59:59') {
				window.location.reload()
			} 
			return (
				<div>
		  {/* {this.formatTime(this.state.seconds)} */}
		</div>
	  );
	}
  }

	function Mediabox (){
		return (
				<div className='App'>
					<ReactPlayer width='1122px' height='632px' playing='true' loop='true' muted='true' controls='false' url={Url} />
					<Timer></Timer>
				</div>
			)
			
	}

	


			
			
export default Mediabox