import './Widget.css'

import React, { useContext } from 'react'
import ItinerarioContext from '../../context/ItinerarioContext';

const Widget = (props) => {

    const itinerarioCtx = useContext(ItinerarioContext)

    return (
        <div className="widgetWrapper">
            
            <div className={`widgetLoaderWrapper ${itinerarioCtx.cargando ? 'loaderOverlayActive':''}`}>
                <div className="loading"></div>
            </div>
            
            <div className={`loaderOverlay ${itinerarioCtx.cargando ? 'loaderOverlayActive':''}`}></div>
            <div className="widget">
            <div className="widgetTitle2">
                <div className="widgetTitle5">
                {/* <div className={ props.tituloLateral3 ? 'titulo lateral':'titulo5' }>{props.titulo5}
                </div> */}
                

                <div className="widgetTitle">
                    
                    <div className={ props.titulo5Lateral ? 'titulo lateral':'titulo5' }>{props.titulo5} /
                    { props.tituloIngles5 
                        ? <span className="widgetEnglishTitle">{props.tituloIngles5}</span>
                        : ''
                    }
                    { props.subtitulo
                        ?   (
                            <h3>{props.subtitulo}</h3>
                            )
                            :   ('')            
                        }
                        </div>

                </div>

                <div className={ props.tituloLateral2 ? 'titulo lateral':'titulo2' }>{props.titulo2}
                </div>
                </div>
                </div>
                <div className="widgetTitle4">
                <div className={ props.tituloLateral4 ? 'titulo lateral':'titulo4' }>{props.titulo4}
                </div>
                </div>
                {/* <div className={ props.subtituloLateral4 ? 'titulo lateral':'subtitulo4' }>{props.subtitulo4} */}
                <div className="widgetTitle3">
                <div className={ props.tituloLateral3 ? 'titulo lateral':'titulo3' }>{props.titulo3}
                </div>
                </div>
                {/* <div className="widgetTitle">
                    
                    <div className={ props.tituloLateral ? 'titulo lateral':'titulo' }>{props.titulo} /
                    { props.tituloIngles 
                        ? <span className="widgetEnglishTitle">{props.tituloIngles}</span>
                        : ''
                    }
                    { props.subtitulo
                        ?   (
                            <h3>{props.subtitulo}</h3>
                            )
                            :   ('')            
                        }
                        </div>

                </div> */}


                <div className="widgetBody">
                    {props.cuerpo}
                </div>

            </div>
        </div>
        
    )
}

export default Widget