import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Tarifa.css'
import Tickets from '../../assets/images/tickets.svg'

const Tarifa = (props) => {
	
	const [tarifa, setTarifa] = useState(0)
	const numberFormat = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP'})
	
	useEffect( () => {
		const apiUrl =  `https://api-tarifa.tabsa.cl/api/tarifa/origen/${props.origen}/destino/${props.destino}`
		console.log(`tarifa ${apiUrl}`)
		const fetchData = async () => {
			const result = await axios(apiUrl)
			const data = await result.data
			setTarifa(data)
		}
		fetchData()
	}, [])

	return (
		tarifa
		? 	( tarifa.info.resultados > 0
				?	(
					<div className="tarifarioContent">
						<div className="tarifas">							
							<div className="tarifaPrimaria">							
								<div className="tarifaPrimariaTitulo">
									<h1>{tarifa.info.origen}<svg className="arrow" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"/></svg> {tarifa.info.destino}</h1>
								</div>
								<div className="tarifaPrimariaContenido">									
									<div className="tarifaPrimariaIcono">
										<img src={Tickets} alt="Tickets"/>
									</div>
									<div className="tarifaPrimariaPrecios">
										{
										tarifa.data.map( ({id,precio,tarifa, tipo}) => (
											tipo === 1
												?	(
													<div key={`tarifa-${id}`} className="tarifaPersona">
														<div className="descTarifa">{tarifa.toLowerCase()}</div>
														<div className="precioTarifa">{numberFormat.format(precio)}</div>
													</div>
													)
												:	''	
										))
										}
									</div>
								</div>
							</div>
							<div className="tarifaSecundaria">
								<table className="table">
									<tbody>
										<tr className="cabecera">
											<th>Tarifa /<span className="tarifaIngles">Rate</span></th>
											<th className="precioTarifa">Precio ($CLP) /<span className="tarifaIngles">Price</span></th>
										</tr>
										{tarifa.data.map( ({id,precio,tarifa, tipo}) => (
											tipo !== 1
												?	(
													<tr key={`tar-${id}`}>
														<td className="nombreTarifa" >{tarifa.toLowerCase()}</td>
														<td className="textoDerecha">{numberFormat.format(precio)}</td>
													</tr>
													)
												:	''	
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					)
				:	<h1>Las tarifas se están actualizando</h1>
			)
		: 	"Cargando tarifas..."
	)
}

export default Tarifa;