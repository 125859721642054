import './Cruces.css'

import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/es'
import axios from 'axios'
import ItinerarioContext from '../../context/ItinerarioContext';
import useInterval from '../../Hooks'
import Estado from './Estado'

const Cruces = (props) => {
    
    const [cruce, setCruce] = useState(0)
    const itinerarioCtx = useContext(ItinerarioContext)
    const hoy = moment()
    const fecha = moment(props.fecha, 'DD-MM-Y')
    var anterior = ''
    moment.locale('es')
    
    const estados = {
        40: { es: 'Arribó', en: 'Arribó', class: 'normal'},
        24: { es: 'En Viaje', en: 'On Route', class: 'normal'},
        8: { es: 'Normal', en: 'Normal', class: 'normal'},
        4: { es: 'Suspendido', en: 'Suspended', class: 'suspendido'},
        2: { es: 'Cancelado', en: 'Canceled', class: 'cancelado'},
        0: { es: 'Cancelado', en: 'Canceled', class: 'cancelado'}

    }

	useEffect( () => {
        if ( props.hoy === true) {
            if (moment(props.fecha, 'DD-MM-Y').format('DD') < hoy.format('DD')) {
                window.location.reload()
            } 
        }
		const fetchData = async () => {
            fetchTimeout()
            // const apiUrl2 = `https://api-cruce.tabsa.cl/api/cruce/primera-angostura`
            const apiUrl = `https://api-cruce.tabsa.cl/api/cruce/${props.cruces}/fecha/${moment(props.fecha, 'DD-MM-Y').format('DD-MM-Y')}/embarcadero/${itinerarioCtx._ID_EMBARCADERO}`
            console.log(apiUrl)
            const result = await axios(apiUrl)
            const data = await result.data
            // const result2 = await axios(apiUrl2)
            // const data2 = await result2.data2
            // setCruce(data2)
            setCruce(data )
            if (props.hoy ) {
                itinerarioCtx.updateNextTrip(data)	
            }
            itinerarioCtx.updateCargando(false)
        }
		fetchData()
    }, [itinerarioCtx.cargando])
    
    const fetchTimeout = () => {
        console.log('start timetout!')
        setTimeout( function() {
            itinerarioCtx.updateCargando(false)
        }, 15000)
    }

    useInterval( () => {
        
        itinerarioCtx.updateCargando(true)

    }, 60000)

    return (
        <div className="crucesWrapper">
        { ! cruce 
            ? ""
            : (
                cruce.info.resultados < 1
                ?   (
                        <>
                        <h3>
                            { hoy.format('DD-MM-Y') === fecha.format('DD-MM-Y') ? 'Hoy, ':''} {fecha.format('dddd',)} {fecha.format('DD/MM/Y')}
                            <small>{hoy.format('DD-MM-Y') === fecha.format('DD-MM-Y') ? 'Today, ':''} {fecha.locale('en').format('dddd')} {fecha.format('DD/MM/Y')}</small>
                        </h3>
                            No hay cruces programados para esta fecha.
                        </>
                    )
                :   (
                        <>
                        <h3>
                            { hoy.format('DD-MM-Y') === fecha.format('DD-MM-Y') ? 'Hoy, ':''} {fecha.format('dddd')} {fecha.format('DD/MM/Y')} /
                            <small>
                                { hoy.format('DD-MM-Y') === fecha.format('DD-MM-Y') ? 'Today, ':''} {fecha.locale('en').format('dddd')} {fecha.format('DD/MM/Y')}
                            </small>
                        </h3>
                        <table className={`table ${ hoy ? '':'smaller'}`}>
                            <thead>
                                <tr className="cabecera">
                                    <th className="origen"><center>Origen <span className="cabeceraIngles">Origin</span></center></th>
                                    <th className="destino"><center>Destino <span className="cabeceraIngles">Destination</span></center></th>
                                    <th className="embarque"><center>Embarque <span className="cabeceraIngles">Boarding</span></center></th>
                                    <th className="zarpe"><center>Zarpe <span className="cabeceraIngles">Departure</span></center></th>
                                    <th className="cabeceraEstado"><center>Estado <span className="cabeceraIngles">Status</span></center></th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    cruce.data.map(({ id, origen, destino, zarpeOriginal, estado, presentacion }) => {
                                        anterior =  moment(moment(zarpeOriginal).format('HH:mm').toString(), 'HH:mm').isBefore(moment(moment(presentacion).format('HH:mm').toString(), 'HH:mm')) ? true:false
                                        return (
                                            <tr key={`cruce-${id}`}>
                                                <td className="origen">{origen}</td>
                                                <td className="destino">{destino}</td>
                                                <td className="embarque textoDerecha">
                                                    {moment(presentacion).format('HH:mm') } 
                                                    <span style={{fontSize:'18px'}}> 
                                                        {anterior ? ` (${moment(presentacion).format('DD/MM')})`:''}
                                                    </span>
                                                </td>
                                                <td className="zarpe textoDerecha">{moment(zarpeOriginal).format('HH:mm')}</td>
                                                <td className="cabeceraEstado"><Estado es={estados[estado].es} en={estados[estado].en} estilo={estados[estado].class} /></td>
                                                
                                            </tr>
                                        )
                                    }
                                    
                                    
                                    )
                                }
                            </tbody>
                        </table>
                        </>
                    )
            )
        } 
        </div>
    )
}


export default Cruces