import React from 'react'

const Texto = () => {
    return (
        <div>
            <h1></h1>
        </div>
    )
}

export default Texto
