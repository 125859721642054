import React, { useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import moment from 'moment'
import Itinerario from './components/Itinerario/Itinerario'
import Mediabox from './components/mediabox/Mediabox'
import ItinerarioContext from './context/ItinerarioContext';
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Alerta from './components/notificacion/Alerta'
import Noticias from './components/noticias/Noticias'
import Texto from './components/texto/Texto'
<meta charset="utf-8"></meta>

const App = (props) => {
    
    const hoy = moment()
    const [cargando, setCargando] = useState(true)
    const [nextTrip, setNextTrip] = useState(0)
    const [_ID_EMBARCADERO, _SET_ID_EMBARCADERO] = useState( localStorage.getItem('id_embarcadero') ? localStorage.getItem('id_embarcadero'):0 )

    const updateCargando = (estado) => { setCargando(estado) }
	const updateNextTrip = (estado) => { setNextTrip(estado) }

    const setEmbarcadero = (embarcadero) => { _SET_ID_EMBARCADERO(embarcadero) }
    const handleKey = (e) => {
        // r: Recarga
        if (e.keyCode === 82) {
            window.location.reload()
        }
        // a: Todos
        if (e.keyCode === 65 ) {
            localStorage.setItem('id_embarcadero', 0)
            localStorage.setItem('ubicacion', 'TODOS')
            window.location.reload()
        }
        // p: PUQ
        if (e.keyCode === 80 ) {
            localStorage.setItem('id_embarcadero', 1)
            localStorage.setItem('ubicacion', 'Punta Arenas')

            window.location.reload()
        }

        // o: porvenir
        if (e.keyCode === 79 ) {
            localStorage.setItem('id_embarcadero', 2)
            localStorage.setItem('ubicacion', 'Porvenir')
            window.location.reload()
        }

        // w: Puerto Williams
        if (e.keyCode === 87 ) {
            localStorage.setItem('id_embarcadero', 3)
            localStorage.setItem('ubicacion', 'Puerto Willimas')
            window.location.reload()
        }

        // n: Natales
        if (e.keyCode === 78 ) {
            localStorage.setItem('id_embarcadero', 41)
            localStorage.setItem('ubicacion', 'Puerto Natales')
            window.location.reload()
        }

        // n: Tortel
        if (e.keyCode === 84 ) {
            localStorage.setItem('id_embarcadero', 46)
            localStorage.setItem('ubicacion', 'Caleta Tortel')
            window.location.reload()
        }

        // m: Magdalena
        if (e.keyCode === 77 ) {
            localStorage.setItem('id_embarcadero', 9)
            localStorage.setItem('ubicacion', 'Caleta Tortel')
            window.location.reload()
        }
        
    }

    useEffect( () => {
        window.addEventListener('keydown', handleKey)
        return () => {
            window.removeEventListener('keydown', handleKey)
        }
    })
    
	return (
        <Router
            basename={"/"}
        >
        <ItinerarioContext.Provider value={ {cargando, updateCargando, nextTrip, updateNextTrip, _ID_EMBARCADERO, setEmbarcadero } }>
            <br></br>
            <Alerta />
            <div className="container">
                <Header />
                <br></br>
                <br></br>

                <section className="content">
                    <div className="row flex-space-between">
                        <Mediabox />
                        <Itinerario fecha={hoy.format('DD-MM-Y')} cruces="6"/>

                    </div>
                </section>
                
                <Footer />
                <Noticias />
                <Texto />
            </div>
        </ItinerarioContext.Provider>
        </Router>
	)
}

export default App;