import React from 'react'
import './Itinerario.css'
import moment from 'moment'
import Widget from '../widget/Widget'
import Cruces from '../cruces/Cruces'
import PD from '../pd/Pd'
import Widget2 from '../widget2/Widget2'
import Estado from '../cruces/Estado'
import ClimaWidget from '../clima/ClimaWidget'
import Reloj from '../reloj/Reloj'
import '../footer/Footer.css'
import '../../index.css'
import RV from '../rv/Rv'
import Onroute from '../Onroute/Onroute'


const Itinerario = (props) => {
		
	return (
		<div id="itinerario">
			<Widget 
				// titulo2="Cruce Primera Angostura"
				// cuerpo={
				// 	<>

				// 	</>
				// }
				titulo5="Cruces Regulares"
				tituloIngles5="Regular Departures"

				titulo4={<RV></RV>}
				titulo2={<PD></PD>}

				subtitulo4="probando"

				titulo3="-----------------------------------------------------------------------------------"
				
				// titulo="Próximos cruces"
				// tituloIngles="Next departures"
				cuerpo={
					<>
						<Cruces cruces='6' fecha={props.fecha} hoy={true}></Cruces>
						
						{/* <Cruces cruces='5' fecha={props.fecha} hoy={true}></Cruces> */}
						<Cruces cruces='5' fecha={moment(props.fecha, 'DD-MM-Y').add(1, 'day').format('DD-MM-Y')}></Cruces>

						{/* <Cruces cruces='4' fecha={moment(props.fecha, 'DD-MM-Y').add(1, 'day').format('DD-MM-Y')}></Cruces> */}

						{/* <Onroute ></Onroute> */}

						{/* _________________________________________________________________________________________


					
					<PD> </PD> */}

					</>
				}
				>
			</Widget>
			<div id="masInformacion2" >
                    {/* <ClimaWidget />*/} <Reloj />
                </div>

		</div>
	)
}

export default Itinerario