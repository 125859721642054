import React from 'react'
import Clock from 'react-live-clock';
import moment from 'moment'
import 'moment/locale/es'

import './Reloj.css'

const Reloj = (props) => {
    
    const fecha = moment()
	return (
        <div className="calendario">
            <><span className="fecha">{fecha.format('dddd')} {fecha.format('DD [de] MMMM')}</span></>
            <Clock className="reloj" ticking={true} format={'HH:mm:ss'}></Clock>
        </div>
    )
}

export default Reloj;