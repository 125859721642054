import React from 'react'
import Tarifa from './Tarifa'
import Carousel from 're-carousel'


const TarifaWidget = (props) => {
		

	return (
        <div className="tarifaContainer">
            <Carousel loop auto duration={4500} axis='Y'><Tarifa origen='1' destino='2' key="tarifa-1"/><Tarifa origen='1' destino='3' key="tarifa-2"/></Carousel>
        </div>
     
	)
}

export default TarifaWidget;