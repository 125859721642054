import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Carousel from 're-carousel'
import lpa2 from '../../assets/images/Dialogo-Sur-logo-2.png'
import './Noticias.css'
import ClimaWidget from '../clima/ClimaWidget'
import Reloj from '../reloj/Reloj'


<meta charset="utf-8"></meta>

var normalize = (function() {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç", 
        to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};
   
    for(var i = 0, j = from.length; i < j; i++ )
        mapping[ from.charAt( i ) ] = to.charAt( i );
   
    return function( str ) {
        var ret = [];
        for( var i = 0, j = str.length; i < j; i++ ) {
            var c = str.charAt( i );
            if( mapping.hasOwnProperty( str.charAt( i ) ) )
                ret.push( mapping[ c ] );
            else
                ret.push( c );
        }      
        return ret.join( '' );
    }
   
  })();

const Noticia = (props) => {
	
	const [noticias, setNoticias] = useState(0)
	const [mostrarNoticias, setMostrarNoticias] = useState(0)

	useEffect( () => {
				
		const api = normalize('https://dialogosur.cl/wp-json/wp/v2/posts')
		// const api = 'https://dialogosur.cl/feed/'
		const fetchNews = async () => {
			const result = await axios(api)
			const data = await result.data
			setNoticias(data)
		}
		fetchNews()
	},[])

	useEffect( () => {
		if (noticias) {
			setMostrarNoticias(true)
		}
	}, [noticias])

	return (
		mostrarNoticias

		?	(
				<div id="newsTicker">
					<Carousel axis='x' loop auto duration={4500} >
						{
							noticias.map( ({id, title}) => {
								return ( 
									<section>
									<div key={`noticia-${id}`} className="noticia">
									<img src={lpa2} alt="Noticias de Diálogo Sur" className="imagenNoticia"/> { title.rendered.length > 110 ? `${title.rendered.substring(0, 100)}...`:title.rendered }
									
									</div>
									</section>
								)
							})
						}
					</Carousel>
				</div>
			)
		
		
		:	(
				<div></div>
			)
	)
}

export default Noticia