import React from 'react'
import Notificacion from '../notificacion/Notificacion'
import Logo from '../logo/Logo'
import Cupo from '../cupo/Cupo'

const Header = () => {

    return (
        <section className="header">
            <div className="row flex-space-between">
                <Notificacion />
                {/* <Cupo /> */}
                <Logo />
            </div>
        </section>
    )
}

export default Header;
